@import url(//fonts.googleapis.com/css?family=Open+Sans);
@font-face {
    font-family: "CVS Health Sans";
    src: url(/static/media/CVSHealthSans_W_Rg.309b1773.woff);
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.CVS_Sans_10pt_LIG_BLK {
    font-size: 10pt;
    font-weight: 350;
    color: #000;
    font-family: "CVS Health Sans" !important;
}
.CVS_Sans_10pt_LIG_ITC_BLK {
    font-size: 10pt;
    font-weight: 350;
    color: #000;
    font-style: italic;
    font-family: "CVS Health Sans" !important;
}
.CVS_Sans_12pt_MED_BLK {
    font-size: 12pt;
    font-weight: 400;
    color: #000;
    font-family: "CVS Health Sans" !important;
}
.OpenSans_Med_10pt_BLK {
    font-weight: 350;
    color: #000;
    font-family: "Open Sans" !important;
}
.CVS_Sans_REG_10pt_White {
    font-size: 10pt;
    color: #000;
    font-family: "CVS Health Sans" !important;
}

.ant-picker-dropdown {
    z-index: 9999 !important;
}

.ant-picker-dropdown .ant-picker-footer .ant-picker-ranges .ant-picker-now-btn {
    color: #4e1169;
}

.ant-picker-dropdown .ant-picker-footer .ant-picker-ranges .ant-picker-ok button {
    background: #4e1169;
    border-color: #4e1169;
    color: #fff !important;
}

.form-group {
    width: 100%;
    height: 100%;
    position: relative;
}

.form-group:has(div.ant-picker-focused) .label span {
    color: #7d3f98;
}

.form-group:has(div.ant-picker-focused) .label::before {
    border-top-width: 2px !important;
}

.form-group .custom-time-picker {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.form-group .custom-time-picker:hover {
    border-color: rgba(0, 0, 0, 0.87);
}

.form-group .ant-picker-focused {
    border-color: #7d3f98 !important;
    border-width: 2px;
    box-shadow: none;
}

.form-group .custom-time-picker .ant-picker-input>input {
    font-size: 16px;
}

.form-group .label {
    position: absolute;
    z-index: 10;
    top: -9px;
    left: 8px;
    margin: 0;
    padding: 0 4px;
    pointer-events: none;
    line-height: 1;
}

.form-group .label::before {
    display: inline-block;
    content: '';
    position: absolute;
    left: 0;
    top: 9px;
    width: 100%;
    border-top: 2px solid #fff;
    z-index: 1;
}

.form-group .label span {
    position: relative;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.87);
    z-index: 2;
}

.ant-picker-time-panel-column::-webkit-scrollbar {
    display: none;
}

.ant-picker-time-panel-column:nth-child(2) {
    overflow-y: unset !important;
}

.ant-picker-time-panel-column:nth-child(3) {
    overflow-y: unset !important;
}

#draggableForModal {
    position: absolute;
    pointer-events: none;
    height: 100%;
    width: 100%;
    z-index: 9999999;
}
